import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { lastValueFrom } from 'rxjs';
import { UserTokenData, UserValidationCode } from '../models/user.model';
import { ApiResponse } from '../models/api-response.model';
import { Gender } from '../models/gender.model';
import { CategoryData } from '../models/category.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  clickedcategory: any = [];
  private baseUrl = environment.baseUrl;

  constructor(private readonly httpClient: HttpClient) { }

  getData(url) {
    return this.httpClient.get(this.baseUrl + url);
  }

  postData(url, data) {
    return this.httpClient.post(this.baseUrl + url, data);
  }

  disconnectOneSignal(data: any) {
    const url = this.baseUrl + 'Chat/disconnectOneSignal';
    return this.httpClient.post(url, data);
  }

  getDataWithToken(url) {
    return this.httpClient.get(this.baseUrl + url);
  }

  postDataWithToken(url, data) {
    return this.httpClient.post(this.baseUrl + url, data);
  }

  connectToChat(data: any) {
    const url = this.baseUrl + 'Chat/connectToOneSignal';
    return this.httpClient.post(url, data);
  }

  getAll() {
    const url = this.baseUrl + 'user/getAll';
    return this.httpClient.post(url, null);
  }
  getUser(data) {
    const url = this.baseUrl + 'User/getUser';
    return this.httpClient.post(url, data);
  }

  async register(data: any): Promise<ApiResponse<UserTokenData>> {
    const url = this.baseUrl + 'User/create';
    return lastValueFrom(
      this.httpClient.post<ApiResponse<UserTokenData>>(url, data)
    );
  }

  login(userEmail: string, userPassword: string): Promise<any> {
    const datalogin = {
      accessData: userEmail,
      password: userPassword,
    };

    const url = this.baseUrl + 'User/login';
    return lastValueFrom(this.httpClient.post(url, datalogin));
  }

  getallprofiles(data: any) {
    const url = this.baseUrl + 'Profile/getAll';
    return this.httpClient.post(url, data);
  }

  like(data: any) {
    const url = this.baseUrl + 'PublicationLike/create';
    return this.httpClient.post(url, data);
  }

  getProfile(id: string) {
    const url = this.baseUrl + `Profile/get?idProfile=${id}`;
    return this.httpClient.get(url);
  }

  retrieveGenders(): Promise<ApiResponse<Gender[]>> {
    const url = this.baseUrl + 'Gender/getAll';
    return lastValueFrom(this.httpClient.get<ApiResponse<Gender[]>>(url));
  }

  getimage(data: any) {
    const url = this.baseUrl + 'Image/get';
    return this.httpClient.post(url, data);
  }

  createShippment(json: {
    addressFromId: any;
    addressToId: any;
    width: any;
    height: any;
    length: any;
    weight: any;
    amount: any;
    personalized: any;
    providerName: string;
    profileId: string;
  }) {
    const url = this.baseUrl + 'Shippment/create';
    return this.httpClient.post(url, json);
  }

  resetPass(data: any, code) {
    const url = this.baseUrl + 'User/recoverPassword?code=' + code;
    return this.httpClient.post(url, data);
  }

  validarCodePhone(data: any) {
    const url = this.baseUrl + 'CodeValidation/ValidateCodePhone';
    return this.httpClient.post(url, data);
  }

  createprofile(data: any) {
    const url = this.baseUrl + 'User/createProfile';
    return this.httpClient.post(url, data);
  }

  updateProfile(data: any) {
    const url = this.baseUrl + 'Profile/update';
    return this.httpClient.post(url, data);
  }

  getAllCategories(data: any): Promise<CategoryData> {
    const url = this.baseUrl + 'Category/getAll';
    return lastValueFrom(this.httpClient.post<CategoryData>(url, data));
  }

  subcategoriesbycategoriid(data: any) {
    const url = this.baseUrl + 'SubCategory/getSubcategoriesByCategoryId';
    return this.httpClient.post(url, data);
  }

  getPublicationsBySubcategory(data: any) {
    const url = this.baseUrl + 'Publication/getPublicationsBySubcategory';
    return this.httpClient.post(url, data);
  }

  getSubcategoriesMostUsed(data: any) {
    const url = this.baseUrl + 'Subcategory/getSubcategoriesMostUsed';
    return this.httpClient.post(url, data);
  }

  getPublicationsByCategory(data: any) {
    const url =
      this.baseUrl +
      `Publication/getPublicationsByCategory/?id=${data.id}&page=${data.page}`;
    return this.httpClient.get(url);
  }

  getPublicationdetailsbyID(data: any) {
    const url = this.baseUrl + 'Publication/get';
    return this.httpClient.post(url, data);
  }
  getPublicationbyID(data: any) {
    const url = this.baseUrl + 'Publication/getPublicationDetails';
    return this.httpClient.post(url, data);
  }

  sendmailtoframeworknet(data: any) {
    const url = this.baseUrl + 'Email/sendEmail';
    return this.httpClient.post(url, data);
  }

  createPublicationComment(data: any) {
    const url = this.baseUrl + 'PublicationComment/create';
    return this.httpClient.post(url, data);
  }

  getPublicationComment(data: any) {
    const url =
      this.baseUrl + 'PublicationComment/getPublicationCommentsByPublication';
    return this.httpClient.post(url, data);
  }

  getReviewTypeAll(data: any) {
    const url = this.baseUrl + 'ReviewType/getAll';
    return this.httpClient.post(url, data);
  }

  getMyValorationToProfileId(data: any) {
    const url = this.baseUrl + 'ProfileReview/getMyValorationToProfileId';
    return this.httpClient.post(url, data);
  }

  createProfileReview(data: any) {
    const url = this.baseUrl + 'ProfileReview/create';
    return this.httpClient.post(url, data);
  }

  getAmountOfSellsByProfileId(data: any) {
    const url = this.baseUrl + 'Sell/getAmountOfSellsByProfileId';
    return this.httpClient.post(url, data);
  }

  getAmountOfBuysByProfileId(data: any) {
    const url = this.baseUrl + 'Sell/getAmountOfBuysByProfileId';
    return this.httpClient.post(url, data);
  }

  getAmountOfShippmentsByProfileId(data: any) {
    const url = this.baseUrl + 'Sell/getAmountOfShippmentsByProfileId';
    return this.httpClient.post(url, data);
  }

  getPublicationsByProfile(data: any) {
    const url = this.baseUrl + 'Publication/getPublicationsByProfile';
    return this.httpClient.post(url, data);
  }

  getValorationByProfileId(data: any) {
    const url = this.baseUrl + 'ProfileReview/getValorationByProfileId';
    return this.httpClient.post(url, data);
  }

  getProfileReviewsByProfileId(data: any) {
    const url = this.baseUrl + 'ProfileReview/getProfileReviewsByProfileId';
    return this.httpClient.post(url, data);
  }

  getSellerLevelByProfileId(data: any) {
    const url = this.baseUrl + 'Profile/getSellerLevelByProfileId';
    return this.httpClient.post(url, data);
  }

  createAddress(data: any) {
    const url = this.baseUrl + 'Address/create';
    return this.httpClient.post(url, data);
  }

  createFavoriteSeller(data: any) {
    const url = this.baseUrl + 'FavoriteSeller/create';
    return this.httpClient.post(url, data);
  }

  getFavoriteSeller(data: any) {
    const url = this.baseUrl + 'FavoriteSeller/getFavoriteSellersByProfileId';
    return this.httpClient.post(url, data);
  }

  deleteFavoriteSeller(data: any) {
    const url = this.baseUrl + 'FavoriteSeller/delete';
    return this.httpClient.post(url, data);
  }

  searchPublication(data: any) {
    const url = this.baseUrl + 'Publication/searchPublication';
    return this.httpClient.post(url, data);
  }

  getHomePublications(data: any) {
    const url = this.baseUrl + 'Publication/getHomePublications';
    return this.httpClient.get(url, data);
  }

  getAllPublications(index) {
    const url =
      this.baseUrl + 'Publication/getActivePublications?page=' + index;
    return this.httpClient.post(url, null);
  }

  getActualVersionAndroid() {
    const url = this.baseUrl + 'Version/getActualVersionAndroid';
    return this.httpClient.post(url, null);
  }

  getActualVersionIos() {
    const url = this.baseUrl + 'Version/getActualVersionIos';
    return this.httpClient.post(url, null);
  }

  getLastRequiredVersionAndroid(): Promise<any> {
    const url = this.baseUrl + 'Version/getLastRequiredVersionAndroid';
    return lastValueFrom(this.httpClient.post(url, null));
  }

  getLastRequiredVersionIos(): Promise<any> {
    const url = this.baseUrl + 'Version/getLastRequiredVersionIos';
    return lastValueFrom(this.httpClient.post(url, null));
  }

  deleteProfile(data) {
    const url = this.baseUrl + 'Profile/delete';
    return this.httpClient.post(url, data);
  }

  deleteUser(data) {
    const url = this.baseUrl + 'User/delete';
    return this.httpClient.post(url, data);
  }

  cancelMovement(data) {
    const url = this.baseUrl + 'movement/cancelMovement';
    return this.httpClient.post(url, data);
  }

  getChatId(data) {
    const url = this.baseUrl + 'Chat/getChatId';
    return this.httpClient.post(url, data);
  }

  createIdentityValidation(data) {
    const url = this.baseUrl + 'IdentityValidation/create';
    return this.httpClient.post(url, data);
  }

  addImageToIdentityValidation(data) {
    const url =
      this.baseUrl + 'IdentityValidation/addImageToIdentityValidation';
    return this.httpClient.post(url, data);
  }

  removeImagesIdentityValidation(data) {
    const url =
      this.baseUrl + 'IdentityValidation/removeImagesIdentityValidation';
    return this.httpClient.post(url, data);
  }

  hasPendingIdentityValidation(data) {
    const url =
      this.baseUrl + 'IdentityValidation/hasPendingIdentityValidation';
    return this.httpClient.post(url, data);
  }
  getChatSupport(data: any) {
    const url = this.baseUrl + 'Chat/getChatSupport';
    return this.httpClient.post(url, data);
  }
}
